import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { chunk } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import {
  AUTHORITY_TYPE,
  COLLATERAL_TYPE,
  LOAN_STATUS,
  OTP_TYPE,
  SETTLEMENT_METHOD_TYPE,
  SUCCESS_INVOICE_STATUS,
} from 'enums';
import useMounted from 'hooks/useMounted';
import type { SignInModel } from 'models/SignInModel';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { InvoiceAdditionalDataVO } from 'models/vo/InvoiceAdditionalDataVO';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import {
  requestAnchorSuccessInvoiceDelete,
  requestAnchorSuccessInvoiceDetail,
  requestAnchorSuccessInvoiceDownload,
} from 'utils/http/api/anchor/success-invoices';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import { FormInput, FormRadioWrap } from '../../../../../components/stateless/CommonForm';
import FormRadio from '../../../../../components/stateless/CommonForm/form-radio/FormRadio';

function AnchorInvoiceDetail() {
  const { t } = useTranslation(['format']);
  const { successInvoiceId } = useParams<any>();
  const signInModel: SignInModel | null = getSignIn();
  const modal = useModal();

  const history = useHistory();
  const mounted = useMounted();
  const [successInvoiceDetail, setSuccessInvoiceDetail] = useState<SuccessInvoiceDetailVOModel>();
  const [clientAuthType, setClientAuthType] = useState<FinancierClientAuthSettingVOModel>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const successInvoiceDetail = await requestAnchorSuccessInvoiceDetail(successInvoiceId);
      const clientAuthTypeData = await requestAnchorClientAuthByFinancierId(successInvoiceDetail.financierEnterpriseId);

      ReactDOM.unstable_batchedUpdates(() => {
        setSuccessInvoiceDetail(successInvoiceDetail);
        setClientAuthType(clientAuthTypeData);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const showInvoiceDeleteModal = (e: any) => {
    e.preventDefault();
    const showInvoiceDeleteOKModal = () => {
      const invoiceDeleteOKModalOptions: ModalOptions = {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          history.push(ROUTES_AC.VIEW_TRANSACTION.INVOICE_LIST);
        },
      };
      modal.show(<h6>{t('text:The_invoice_has_been_deleted')}</h6>, invoiceDeleteOKModalOptions);
    };

    const requestDelete = async (verificationCode?: UserVerificationCodeRequest) => {
      try {
        await requestAnchorSuccessInvoiceDelete({
          successInvoiceId,
          financierId: successInvoiceDetail?.financierEnterpriseId!,
          otpCode: verificationCode?.otpCode,
          queryValue: verificationCode?.queryValue,
        });
        showInvoiceDeleteOKModal();
      } catch (e) {
        modal.show(e);
      }
    };

    const showOtpVerificationModal = () => {
      const verificationCode: UserVerificationCodeRequest = {};

      if (!clientAuthType) return;
      if (clientAuthType.otpType === OTP_TYPE.NONE) {
        requestDelete();

        return;
      }

      modal.show(
        <UserVerificationModal
          modalId={modal.id}
          verificationCode={verificationCode}
          requestIdType="financierId"
          requestId={Number(successInvoiceDetail?.financierEnterpriseId)}
          clientAuthSetting={clientAuthType}
        />,
        {
          modalType: ModalType.CONFIRM,
          title: t('text:User_Verification'),
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: () => requestDelete(verificationCode),
        },
      );
    };

    const invoiceDeleteModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => showOtpVerificationModal(),
    };
    modal.show(
      <h6>
        {t('text:Would_you_like_to_delete_the_invoice?')}
        <br />
        {t('text:If_you_delete_the_invoice,_it_can’t_be_return_the_invoice_information_and_you_have_to_register_again')}
      </h6>,
      invoiceDeleteModalOptions,
    );
  };

  const deleteBtnActive = () => {
    if (signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER) {
      if (successInvoiceDetail?.successInvoiceStatus === SUCCESS_INVOICE_STATUS.UNSETTLED) return false;
      if (
        successInvoiceDetail?.loanStatus === null ||
        successInvoiceDetail?.loanStatus === LOAN_STATUS.DEALER_CANCELLED ||
        successInvoiceDetail?.loanStatus === LOAN_STATUS.REJECTED
      ) {
        return true;
      }
    }

    return false;
  };

  const downloadInvoiceFile = async (e: any) => {
    e.preventDefault();

    try {
      await requestAnchorSuccessInvoiceDownload(successInvoiceId);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSuccessInvoiceStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_INVOICE_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="SUCCESS_INVOICE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderGuideMessage = () => {
    if (successInvoiceDetail?.blockedByFinancier) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[t('text:The_Invoice_has_been_blocked'), t('text:Please_check_the_reason_for_block_below')]}
          reason={successInvoiceDetail?.blockedReason}
          reasonTitle={t('text:REASON_FOR_BLOCK')}
        />
      );
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Invoice_Details')} />
      {renderGuideMessage()}
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Invoice_Summary')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={showInvoiceDeleteModal}
              disabled={!deleteBtnActive()}
              size={ButtonSizeEnum.SM}
              data-for="delete-button-tip"
              data-tip
            >
              {t('text:Delete')}
            </Button>
            <ReactTooltip id="delete-button-tip" effect="solid">
              <div className="p-1">
                {t('text:Authorizer_user_can_delete_Invoices_under_the_following_conditions')} :
                <ul>
                  <li>• {t('text:Invoice_for_which_financing_applications_have_not_been_made')}</li>
                  <li>• {t('text:Invoice_for_which_settlement_date_has_not_arrived')}</li>
                </ul>
              </div>
            </ReactTooltip>
          </SectionTitle>
          <FormBorder>
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue
                  col={3}
                  className="information-form__input border-none pointfont"
                  label={t('text:Invoice_Amount')}
                  value={t('format:number', { value: successInvoiceDetail?.invoiceAmount })}
                />
                <FormValue
                  col={3}
                  label={t('text:Original_Payment_Date')}
                  value={t('format:date', {
                    value: successInvoiceDetail?.settlementDate,
                    key: 'date',
                  })}
                />

                <FormValue
                  col={3}
                  label={t('text:Payment_Completion_Date')}
                  value={
                    successInvoiceDetail?.successInvoiceStatus === SUCCESS_INVOICE_STATUS.SETTLED
                      ? t('format:date', {
                          value: successInvoiceDetail?.disbursedDate,
                          key: 'date',
                        })
                      : '-'
                  }
                />
                <FormValue
                  col={3}
                  label={t('text:Payment_Status')}
                  className={
                    'information-form__input border-none ' +
                    getStatusTextClass('SUCCESS_INVOICE_STATUS', successInvoiceDetail?.successInvoiceStatus)
                  }
                  value={t(`code:success-invoice-status.${successInvoiceDetail?.successInvoiceStatus}`)}
                  format="code"
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickSuccessInvoiceStatus}
                />
              </div>
            </FormContents>
            <FormContents backGroundType={BackGroundType.Gray}>
              <div className="row">
                <FormValue
                  col={3}
                  label={t('text:Invoice_Number')}
                  subLabel={`(${t('text:Reference_Number')})`}
                  value={successInvoiceDetail?.invoiceNumber}
                  subValue={successInvoiceDetail?.referenceNumber}
                />
                <FormValue col={3} label={t('text:BOE_Number')} value={successInvoiceDetail?.billOfExchangeNo} />
                <FormValue
                  col={3}
                  label={t('text:Invoice_Issued_Date')}
                  value={t('format:date', {
                    value: successInvoiceDetail?.invoiceIssuedDate,
                    key: 'date',
                  })}
                />
                <FormValue col={3} label={t('text:Currency')} value={successInvoiceDetail?.currencyType} />
              </div>
              <div className="row">
                <FormValue col={3} label={t('text:Anchor_Name')} value={successInvoiceDetail?.anchorClientName} />
                <FormValue col={3} label={t('text:Partner_Name')} value={successInvoiceDetail?.dealerClientName} />
                <FormValue
                  col={3}
                  label={t('text:Financing_Disbursement_Status')}
                  value={successInvoiceDetail?.paid ? t('text:Yes') : t('text:No')}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>

      {(successInvoiceDetail?.invoiceAdditionalDataList?.length ?? 0) > 0 && (
        <div className="content-area">
          <SectionTitle title={t('text:Invoice_Details')} />
          <FormBorder>
            <FormContents backGroundType={BackGroundType.Gray}>
              {chunk(successInvoiceDetail?.invoiceAdditionalDataList, 2).map(
                (chunkedTwoAdditionalData: InvoiceAdditionalDataVO[], i) => (
                  <div key={i} className="row">
                    {chunkedTwoAdditionalData.map((additionalData: InvoiceAdditionalDataVO, l) => (
                      <FormValue
                        key={`${additionalData.name}-${additionalData.value}-${l}`}
                        label={additionalData.name}
                        value={additionalData.value}
                      />
                    ))}
                  </div>
                ),
              )}
            </FormContents>
          </FormBorder>
        </div>
      )}
      <div className="content-area">
        <SectionTitle title={t('text:Settlement_Method')} />
        {successInvoiceDetail?.settlementMethod ? (
          <FormBorder>
            <FormSubtitle title={t('text:Invoice_Settlement_Method')} />
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormRadioWrap label={t('text:Select_a_payment_method_to_pay_for_this_invoice')}>
                  <FormRadio
                    label={t('code:settlement-method-type.FINANCING')}
                    defaultChecked={successInvoiceDetail?.settlementMethod === SETTLEMENT_METHOD_TYPE.FINANCING}
                    disabled
                  />
                  <FormRadio
                    label={t('code:settlement-method-type.SELF_FUNDING')}
                    defaultChecked={successInvoiceDetail?.settlementMethod === SETTLEMENT_METHOD_TYPE.SELF_FUNDING}
                    disabled
                  />
                  <FormRadio
                    label={t('code:settlement-method-type.OTHERS')}
                    defaultChecked={successInvoiceDetail?.settlementMethod === SETTLEMENT_METHOD_TYPE.OTHERS}
                    disabled
                  />
                </FormRadioWrap>
              </div>
              <div className="row">
                <FormInput label={t('text:Reason')} value={successInvoiceDetail.settlementOtherMethodReason} disabled />
              </div>
            </FormContents>
          </FormBorder>
        ) : (
          <GuideMessage
            message={[t('text:Partner_didn_t_select_the_settlement_method_yet')]}
            isImportContentArea
            useContentAreaClassName={false}
          />
        )}
      </div>
      {successInvoiceDetail?.loanStatus !== LOAN_STATUS.DEALER_CREATED &&
        successInvoiceDetail?.loanStatus !== LOAN_STATUS.DEALER_CANCELLED && (
          <div className="content-area">
            <SectionTitle title={t('text:Financing_Information')}>
              {successInvoiceDetail?.loanId ? (
                <div className="flex-end align-items-center me-3">
                  <span className="me-2">{t('text:Go_to_Financing_Details')}</span>
                  <Button
                    size={ButtonSizeEnum.SM}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    className="flex-column-center"
                    onClick={() => {
                      history.push(ROUTES_AC.VIEW_TRANSACTION.FINANCING_LIST, {
                        loanId: successInvoiceDetail?.loanId,
                        loanStatusConditions: [successInvoiceDetail?.loanStatus],
                        invoiceNumber: successInvoiceDetail?.invoiceNumber,
                        referenceNumber: successInvoiceDetail?.referenceNumber,
                        collateralType: COLLATERAL_TYPE.INVOICE,
                        currencyType: successInvoiceDetail?.currencyType,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </div>
              ) : null}
            </SectionTitle>
            <FormBorder>
              <FormContents>
                <div className="row">
                  <FormValue
                    col={3}
                    label={t('text:Disbursed_Date')}
                    value={successInvoiceDetail?.disbursedDate}
                    format="date"
                  />
                  <FormValue
                    col={3}
                    label={t('text:Scheduled_Repayment_Date')}
                    value={successInvoiceDetail?.repaymentDate}
                    format="date"
                  />
                  <FormValue
                    col={3}
                    label={t('text:Repaid_Date')}
                    value={successInvoiceDetail?.repaidDate}
                    format="date"
                  />
                  <FormValue
                    col={3}
                    className={getStatusBadgeClass('LOAN_STATUS', successInvoiceDetail?.loanStatus)}
                    label={t('text:Financing_Status')}
                    value={tableValueManage(t(`code:financing-status.${successInvoiceDetail?.loanStatus}`))}
                    hasStatusDescription={true}
                    showStatusDescriptionFunc={onClickLoanStatus}
                  />
                </div>
              </FormContents>
              <div className="detail-in-file-download-form">
                <div className="d-flex justify-content-between">
                  <label className="information-form__label">{t('text:Tax_Invoice_File')}</label>
                  <input
                    type="text"
                    placeholder={
                      successInvoiceDetail?.scannedInvoiceAttachmentFileName
                        ? successInvoiceDetail?.scannedInvoiceAttachmentFileName
                        : t('text:No_file_attached')
                    }
                    className="upload-file-input"
                    readOnly
                  />
                  <DownloadButton
                    onClick={downloadInvoiceFile}
                    disabled={successInvoiceDetail?.scannedInvoiceAttachmentId ? false : true}
                  />
                </div>
              </div>
            </FormBorder>
          </div>
        )}

      <div className="content-area">
        <SectionTitle title={t('text:Registration_Information')} />
        <FormBorder>
          {/*최종 승인자 정보  */}
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Registration_Confirmation')} />
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Enterprise_Type')}
                value={t(`code:enterprise-type.${successInvoiceDetail?.currentUploadEnterpriseType}`)}
              />
              <FormValue label={t('text:Enterprise_Name')} value={successInvoiceDetail?.currentConfirmEnterpriseName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Person_in_Charge')} value={successInvoiceDetail?.currentConfirmUserName} />
              <FormValue
                label={t('text:Authority')}
                value={t(`code:authority-type.${successInvoiceDetail?.currentUploadUserAuthorityType}`)}
                format="code"
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Registered_Date')}
                value={t('format:datetime', {
                  value: successInvoiceDetail?.currentInvoiceApprovalDateTime,
                  key: 'datetime',
                })}
              />
            </div>
          </FormContents>
          <FormSubtitle backGroundType={BackGroundType.DarkGray} title={t('text:Registration_Request')} />
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Enterprise_Type')}
                value={t(`code:enterprise-type.${successInvoiceDetail?.initialUploadEnterpriseType}`)}
              />
              <FormValue label={t('text:Enterprise_Name')} value={successInvoiceDetail?.initialUploadEnterpriseName} />
            </div>
            <div className="row">
              <FormValue label={t('text:Person_in_Charge')} value={successInvoiceDetail?.initialUploadUserName} />
              <FormValue
                label={t('text:Authority')}
                value={t(`code:authority-type.${successInvoiceDetail?.initialUploadUserAuthorityType}`)}
                format="code"
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Registered_Date')}
                value={t('format:datetime', {
                  value: successInvoiceDetail?.initialInvoiceApprovalDateTime,
                  key: 'datetime',
                })}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default AnchorInvoiceDetail;
