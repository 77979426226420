import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { chunk } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, LOAN_STATUS, SETTLEMENT_METHOD_TYPE, SUCCESS_INVOICE_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import type { SignInModel } from 'models/SignInModel';
import type { InvoiceAdditionalDataVO } from 'models/vo/InvoiceAdditionalDataVO';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import {
  requestFinancierBlockSuccessInvoice,
  requestFinancierDownloadScannedInvoice,
  requestFinancierSuccessInvoice,
  requestFinancierUnblockSuccessInvoice,
} from 'utils/http/api/financier/success-invoices';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { isLoanDisbursed } from 'utils/status';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import { FormInput, FormRadioWrap } from '../../../../../components/stateless/CommonForm';
import FormRadio from '../../../../../components/stateless/CommonForm/form-radio/FormRadio';

function FinancierInvoiceDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const { successInvoiceId } = useParams() as any;
  const [successInvoiceDetailData, setSuccessInvoiceDetailData] = useState<SuccessInvoiceDetailVOModel>();
  const signInModel: SignInModel | null = getSignIn();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    try {
      const successInvoiceDetailResponse = await requestFinancierSuccessInvoice(successInvoiceId);

      setSuccessInvoiceDetailData(successInvoiceDetailResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSuccessInvoiceStatus = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_INVOICE_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="SUCCESS_INVOICE_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickDownload = async (e: any) => {
    e.preventDefault();

    try {
      await requestFinancierDownloadScannedInvoice(successInvoiceId);
    } catch (error) {
      modal.show(error);
    }
  };

  const showBlockModal = (e: any): void => {
    e.preventDefault();

    let reason = '';

    const onClickConfirm = async (): Promise<void> => {
      try {
        await requestFinancierBlockSuccessInvoice(successInvoiceId, {
          reason,
        });

        modal.show(<h6>{t('text:The_invoice_block_has_been_completed')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => fetchAll(),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(
      <>
        <h6>
          {t('text:Would_you_like_to_block_the_invoice?')}
          <br />
          {t('text:If_you_block_the_invoice_it_cannot_be_used_for_any_financing_applications')}
          <br />
          {t('text:Please_enter_the_reason_for_the_block_below')}
        </h6>
        <ReasonModal
          modalId={modal.id}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </>,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirm(),
      },
    );
  };

  const showUnblockModal = (e: any): void => {
    e.preventDefault();

    const onClickConfirm = async (): Promise<void> => {
      await requestFinancierUnblockSuccessInvoice(successInvoiceId);

      try {
        modal.show(<h6>{t('text:The_invoice_has_been_activated')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => fetchAll(),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(<h6>{t('text:Would_you_like_to_activate_the_invoice?')}</h6>, {
      modalSize: ModalSize.NONE,
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => onClickConfirm(),
    });
  };

  const renderGuideMessage = () => {
    if (successInvoiceDetailData?.blockedByFinancier) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[t('text:The_Invoice_has_been_blocked'), t('text:Please_check_the_reason_for_block_below')]}
          reason={successInvoiceDetailData?.blockedReason}
          reasonTitle={t('text:REASON_FOR_BLOCK')}
        />
      );
    }
  };

  return (
    <>
      <BackHeaderTitle title={t('text:Invoice_Details')} />
      {renderGuideMessage()}
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Registered_Invoice_Summary')}>
            {signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER &&
              (!successInvoiceDetailData?.blockedByFinancier ? (
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.RED}
                  onClick={showBlockModal}
                  disabled={successInvoiceDetailData?.collateralized}
                >
                  {t('text:Block')}
                </Button>
              ) : (
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.BLUE}
                  onClick={showUnblockModal}
                  disabled={successInvoiceDetailData?.collateralized}
                >
                  {t('text:Active')}
                </Button>
              ))}
          </SectionTitle>
          <FormBorder>
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormValue
                  col={3}
                  className="information-form__input border-none pointfont"
                  label={t('text:Invoice_Amount')}
                  value={tableValueManage(
                    successInvoiceDetailData?.invoiceAmount,
                    t('format:number', { value: successInvoiceDetailData?.invoiceAmount }),
                  )}
                />
                <FormValue
                  col={3}
                  label={t('text:Original_Payment_Date')}
                  value={tableValueManage(
                    successInvoiceDetailData?.settlementDate,
                    t('format:date', { value: successInvoiceDetailData?.settlementDate, key: 'datetime' }),
                  )}
                />
                <FormValue
                  col={3}
                  label={t('text:Payment_Completion_Date')}
                  value={tableValueManage(
                    successInvoiceDetailData?.disbursedDate,
                    t('format:date', { value: successInvoiceDetailData?.disbursedDate, key: 'datetime' }),
                  )}
                />
                <FormValue
                  col={3}
                  className={
                    'information-form__input border-none ' +
                    getStatusTextClass('SUCCESS_INVOICE_STATUS', successInvoiceDetailData?.successInvoiceStatus)
                  }
                  label={t('text:Payment_Status')}
                  value={tableValueManage(
                    successInvoiceDetailData?.successInvoiceStatus,
                    t(`code:success-invoice-status.${successInvoiceDetailData?.successInvoiceStatus}`),
                  )}
                  hasStatusDescription={true}
                  showStatusDescriptionFunc={onClickSuccessInvoiceStatus}
                />
              </div>
            </FormContents>
            <FormContents>
              <div className="row">
                <FormValue
                  col={3}
                  label={t('text:Invoice_Number')}
                  subLabel={`(${t('text:Invoice_Reference_Number')})`}
                  value={tableValueManage(successInvoiceDetailData?.invoiceNumber)}
                  subValue={tableValueManage(successInvoiceDetailData?.referenceNumber)}
                />
                <FormValue
                  col={3}
                  label={t('text:BOE_Number')}
                  value={tableValueManage(successInvoiceDetailData?.billOfExchangeNo)}
                />
                <FormValue
                  col={3}
                  label={t('text:Invoice_Issued_Date')}
                  value={successInvoiceDetailData?.invoiceIssuedDate}
                  format="date"
                />
                <FormValue
                  col={3}
                  label={t('text:Currency')}
                  value={tableValueManage(successInvoiceDetailData?.currencyType)}
                />
              </div>
              <div className="row">
                <FormValue
                  col={3}
                  label={t('text:Anchor_Name')}
                  value={tableValueManage(successInvoiceDetailData?.anchorClientName)}
                />
                <FormValue
                  col={3}
                  label={t('text:Partner_Name')}
                  value={tableValueManage(successInvoiceDetailData?.dealerClientName)}
                />
                <FormValue
                  col={3}
                  label={t('text:Financing_Disbursement_Status')}
                  value={isLoanDisbursed(successInvoiceDetailData?.loanStatus) ? t('text:Yes') : t('text:No')}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>

      {(successInvoiceDetailData?.invoiceAdditionalDataList?.length ?? 0) > 0 && (
        <div className="content-area">
          <SectionTitle title={t('text:Invoice_Details')} />
          <FormBorder>
            <FormContents backGroundType={BackGroundType.Gray}>
              {chunk(successInvoiceDetailData?.invoiceAdditionalDataList, 2).map(
                (chunkedTwoAdditionalData: InvoiceAdditionalDataVO[], i) => (
                  <div key={i} className="row">
                    {chunkedTwoAdditionalData.map((additionalData: InvoiceAdditionalDataVO, l) => (
                      <FormValue
                        key={`${additionalData.name}-${additionalData.value}-${l}`}
                        label={additionalData.name}
                        value={additionalData.value}
                      />
                    ))}
                  </div>
                ),
              )}
            </FormContents>
          </FormBorder>
        </div>
      )}

      <div className="content-area">
        <SectionTitle title={t('text:Settlement_Method')} />
        {successInvoiceDetailData?.settlementMethod ? (
          <FormBorder>
            <FormSubtitle title={t('text:Invoice_Settlement_Method')} />
            <FormContents backGroundType={BackGroundType.WHITE}>
              <div className="row">
                <FormRadioWrap label={t('text:Select_a_payment_method_to_pay_for_this_invoice')}>
                  <FormRadio
                    label={t('code:settlement-method-type.FINANCING')}
                    defaultChecked={successInvoiceDetailData?.settlementMethod === SETTLEMENT_METHOD_TYPE.FINANCING}
                    disabled
                  />
                  <FormRadio
                    label={t('code:settlement-method-type.SELF_FUNDING')}
                    defaultChecked={successInvoiceDetailData?.settlementMethod === SETTLEMENT_METHOD_TYPE.SELF_FUNDING}
                    disabled
                  />
                  <FormRadio
                    label={t('code:settlement-method-type.OTHERS')}
                    defaultChecked={successInvoiceDetailData?.settlementMethod === SETTLEMENT_METHOD_TYPE.OTHERS}
                    disabled
                  />
                </FormRadioWrap>
              </div>
              <div className="row">
                <FormInput
                  label={t('text:Reason')}
                  value={successInvoiceDetailData.settlementOtherMethodReason}
                  disabled
                />
              </div>
            </FormContents>
          </FormBorder>
        ) : (
          <GuideMessage
            message={[t('text:Partner_didn_t_select_the_settlement_method_yet')]}
            isImportContentArea
            useContentAreaClassName={false}
          />
        )}
      </div>

      {successInvoiceDetailData?.loanStatus !== LOAN_STATUS.DEALER_CREATED &&
        successInvoiceDetailData?.loanStatus !== LOAN_STATUS.DEALER_CANCELLED && (
          <div className="content-area">
            <SectionTitle title={t('text:Financing_Information')}>
              {successInvoiceDetailData?.loanId ? (
                <div className="flex-end align-items-center">
                  <span className="me-2">{t('text:Go_to_Financing_Details')}</span>
                  <Button
                    size={ButtonSizeEnum.SM}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    to={ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(successInvoiceDetailData?.loanId)}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </div>
              ) : null}
            </SectionTitle>
            <FormBorder>
              <FormContents>
                <div className="row">
                  <FormValue
                    col={3}
                    label={t('text:Disbursed_Date')}
                    value={successInvoiceDetailData?.disbursedDate}
                    format="date"
                  />
                  <FormValue
                    col={3}
                    label={t('text:Scheduled_Repayment_Date')}
                    value={successInvoiceDetailData?.repaymentDate}
                    format="date"
                  />
                  <FormValue
                    col={3}
                    label={t('text:Repaid_Date')}
                    value={successInvoiceDetailData?.repaidDate}
                    format="date"
                  />
                  <FormValue
                    col={3}
                    className={getStatusBadgeClass('LOAN_STATUS', successInvoiceDetailData?.loanStatus)}
                    label={t('text:Financing_Status')}
                    value={tableValueManage(t(`code:financing-status.${successInvoiceDetailData?.loanStatus}`))}
                    hasStatusDescription={true}
                    showStatusDescriptionFunc={onClickLoanStatus}
                  />
                </div>
              </FormContents>
              <div className="detail-in-file-download-form">
                <div className="d-flex justify-content-between">
                  <label className="information-form__label">{t('text:Tax_Invoice_File')}</label>
                  <input
                    type="text"
                    placeholder={t('text:No_file_attached')}
                    className="upload-file-input"
                    readOnly={true}
                    defaultValue={
                      successInvoiceDetailData?.scannedInvoiceAttachmentFileName
                        ? successInvoiceDetailData?.scannedInvoiceAttachmentFileName
                        : ''
                    }
                  />
                  <DownloadButton
                    onClick={onClickDownload}
                    disabled={!successInvoiceDetailData?.scannedInvoiceAttachmentId}
                  />
                </div>
              </div>
            </FormBorder>
          </div>
        )}
      <div className="content-area">
        <SectionTitle title={t('text:Registration_Information')} />
        <FormBorder>
          <FormSubtitle title={t('text:Registration_Confirmation')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Enterprise_Type')}
                value={t(`code:enterprise-type.${successInvoiceDetailData?.currentUploadEnterpriseType}`)}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Enterprise_Name')}
                value={tableValueManage(successInvoiceDetailData?.currentConfirmEnterpriseName)}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Person_in_Charge')}
                value={tableValueManage(successInvoiceDetailData?.currentConfirmUserName)}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Authority')}
                value={t(`code:authority-type.${successInvoiceDetailData?.currentUploadUserAuthorityType}`)}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Registered_Date')}
                value={successInvoiceDetailData?.currentInvoiceApprovalDateTime}
                format="datetime"
              />
            </div>
          </FormContents>
          <FormSubtitle title={t('text:Registration_Request')} backGroundType={BackGroundType.DarkGray} />
          <FormContents>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Enterprise_Type')}
                value={t(`code:enterprise-type.${successInvoiceDetailData?.initialUploadEnterpriseType}`)}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Enterprise_Name')}
                value={tableValueManage(successInvoiceDetailData?.initialUploadEnterpriseName)}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Person_in_Charge')}
                value={tableValueManage(successInvoiceDetailData?.initialUploadUserName)}
              />
              <FormValue
                className="information-form__input"
                label={t('text:Authority')}
                value={t(`code:authority-type.${successInvoiceDetailData?.initialUploadUserAuthorityType}`)}
              />
            </div>
            <div className="row">
              <FormValue
                className="information-form__input"
                label={t('text:Registered_Date')}
                value={successInvoiceDetailData?.initialInvoiceApprovalDateTime}
                format="datetime"
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default FinancierInvoiceDetail;
